import { useRef, useState } from "react";
import T from "prop-types";
import { Wrapper } from "./DraggableScroll.styles";

function DraggableScroll({ children }) {
  const ref = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const pointer = useRef({
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const handleDragStart = (e) => {
    if (!ref.current) return;
    e.preventDefault();
    const { offsetLeft, scrollLeft, scrollTop } = ref.current.children[0];
    const startX = e.pageX - offsetLeft;

    pointer.current = { startX, scrollLeft, scrollTop };
    document.body.style.cursor = "grabbing";

    setIsMouseDown(true);
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);

    if (!ref.current) return;
    document.body.style.cursor = "default";
  };

  const handleDrag = (e) => {
    if (!isMouseDown || !ref.current) return;
    e.preventDefault();

    const slider = ref.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - pointer.current.startX) * 1.5;
    slider.scrollLeft = pointer.current.scrollLeft - walkX;
  };

  if (!children) {
    return null;
  }

  return (
    <Wrapper
      ref={ref}
      role="presentation"
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      data-test-id="draggable-scroll-wrapper"
    >
      {children}
    </Wrapper>
  );
}

DraggableScroll.propTypes = {
  children: T.node.isRequired,
};

export default DraggableScroll;
