import { Box, styled } from "components/MUI";
import Image from "next/image";

const StyledUnordered = styled("ul")(({ theme }) => ({
  marginBlockStart: 0,
  marginBlockEnd: theme.spacing(5),
  ...theme.typography.body1,
}));

const StyledUnorderedListItem = styled("li")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
}));

const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ mobile }) => ({
  position: "relative",
  width: "100%",
  height: mobile ? "500px" : "300px",
}));

const SvgImage = styled(Image)(() => ({
  objectFit: "contain",
  objectPosition: "center",
}));

export { StyledUnordered, StyledUnorderedListItem, ImageWrapper, SvgImage };
