import { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";

import { useMediaQuery } from "components/MUI";
import theme from "config/theme";
import Section from "components/Layout/Section";
import { Body, H3, Text } from "components/atoms/Typography";
import DraggableScroll from "components/atoms/DraggableScroll";

import {
  Wrapper,
  StepItem,
  StepItemImageWrapper,
} from "./HighlightingSteps.styles";

function HighlightingSteps() {
  const scrollerRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslations();

  useEffect(() => {
    if (scrollerRef?.current?.clientWidth) {
      setParentWidth(scrollerRef.current.clientWidth);
    }
  }, [scrollerRef?.current?.clientWidth]);

  return (
    <Section
      title={t("steps.title")}
      titleStyleProps={{
        variant: "h1",
        textAlign: "left",
        sx: { maxWidth: "780px" },
      }}
    >
      <DraggableScroll>
        <Wrapper ref={scrollerRef}>
          {[...Array(5).keys()].map((i) => (
            <StepItem
              width={(parentWidth / (parentWidth / 380)) * 0.8}
              key={i}
              tabIndex={0}
            >
              <Text variant="h2" as="p" sx={{ fontWeight: 700 }}>
                {isSmall ? `0${i + 1}` : t(`steps.list.${i + 1}.no`)}
              </Text>
              <H3
                sx={{
                  mb: theme.spacing(3),
                  mt: { md: theme.spacing(3), xs: theme.spacing(1) },
                  width: 210,
                }}
              >
                {t(`steps.list.${i + 1}.title`)}
              </H3>
              <Body sx={{ mb: theme.spacing(2) }}>
                {t(`steps.list.${i + 1}.text`)}
              </Body>
              <StepItemImageWrapper>
                <Image
                  loading="eager"
                  fill
                  alt=""
                  sizes="700px"
                  src={`/images/${t(`steps.list.${i + 1}.image`)}`}
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </StepItemImageWrapper>
            </StepItem>
          ))}
        </Wrapper>
      </DraggableScroll>
    </Section>
  );
}

export default HighlightingSteps;
