/* eslint-disable react/no-unstable-nested-components */
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { Box, Grid, useMediaQuery } from "components/MUI";

import theme from "config/theme";
import { Body, Caption } from "components/atoms/Typography";
import Section from "components/Layout/Section";
import Link from "components/Link";

import {
  ImageWrapper,
  StyledUnordered,
  StyledUnorderedListItem,
  SvgImage,
} from "./CashflowExplanation.styled";

function CashflowExplanation() {
  const t = useTranslations();
  const router = useRouter();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section
      title={t("cashflow.h2")}
      sectionStyleProps={{
        sx: { py: theme.spacing(10) },
      }}
    >
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <ImageWrapper mobile={isSmall}>
            <SvgImage
              fill
              src={
                isSmall
                  ? "/images/cashflow_mobile.svg"
                  : `/images/cashflow/cashflow-${router.locale}.svg`
              }
              alt={t("cashflow.svg_alt")}
              sizes="(max-width: 768px) 0,
              (max-width: 600px) 30vw,
              20vw"
            />
          </ImageWrapper>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Body>
            {t.rich("cashflow.text", {
              strong: (text) => <b>{text}</b>,
              p: (text) => <p>{text}</p>,
            })}
          </Body>
          <StyledUnordered>
            {t.rich("cashflow.list_points", {
              li: (text) => (
                <StyledUnorderedListItem>{text}</StyledUnorderedListItem>
              ),
            })}
          </StyledUnordered>
          <Caption>{t("cashflow.text_caption")}</Caption>
        </Grid>
      </Grid>
      <Box textAlign="center" sx={{ mt: theme.spacing(10) }}>
        <Link
          type="button"
          variant="contained"
          size="large"
          disableElevation
          href={t("links.all_products.link")}
        >
          {t("cashflow.btn_label")}
        </Link>
      </Box>
    </Section>
  );
}

export default CashflowExplanation;
