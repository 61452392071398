import { useTranslations } from "next-intl";
import { Box, Divider, Stack, useMediaQuery } from "components/MUI";

import theme from "config/theme";
import { Body, H2 } from "components/atoms/Typography";
import Section from "components/Layout/Section";
import CustomIcon from "components/atoms/CustomIcon";

const COLUMNS = ["column_1", "column_2", "column_3"];

function Teaser() {
  const t = useTranslations();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section
      sectionStyleProps={{
        sx: {
          py: { md: theme.spacing(10), xs: theme.spacing(6) },
          mt: { md: theme.spacing(10), xs: theme.spacing(0) },
        },
      }}
    >
      <Stack
        direction={isSmall ? "column" : "row"}
        divider={
          <Divider
            orientation={isSmall ? "horizontal" : "vertical"}
            flexItem
            sx={{
              width: { xs: "50%", md: "auto" },
              alignSelf: { xs: "center", md: "auto" },
            }}
          />
        }
        spacing={4}
      >
        {COLUMNS.map((column) => (
          <Box key={column} sx={{ flex: 1, textAlign: "center" }}>
            <CustomIcon
              name={t(`features.${column}.icon`)}
              sx={{ width: 64, height: 64, mb: theme.spacing(2) }}
              fill={theme.palette.custom.brightBlue}
            />
            <H2 sx={{ mb: { xs: theme.spacing(2), md: theme.spacing(4) } }}>
              {t(`features.${column}.title`)}
            </H2>
            <Body component="p">{t(`features.${column}.text`)}</Body>
          </Box>
        ))}
      </Stack>
    </Section>
  );
}

export default Teaser;
