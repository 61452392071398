import T from "prop-types";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { useRouter } from "next/router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Grid, useMediaQuery, Menu, Box } from "components/MUI";
import Link from "components/Link";
import theme from "config/theme";
import { StyledMenuButton } from "./GridButtonView.styles";

function MobileMenu({ categories }) {
  const t = useTranslations();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box textAlign="center" sx={{ mb: theme.spacing(3) }}>
        <StyledMenuButton
          id="categories-button"
          size="large"
          aria-controls={open ? "categories-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClick}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {t(`links.product_category`)}
        </StyledMenuButton>
      </Box>
      <Menu
        id="categories-menu"
        MenuListProps={{
          "aria-labelledby": "categories-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        elevation={1}
        open={open}
        onClose={handleClose}
      >
        {categories.map((category) => (
          <Link
            type="menu_item"
            href={category.slug}
            key={category.slug}
            onClick={handleClose}
            disableRipple
            underline="none"
            isActive={router.asPath === category.slug}
          >
            {category.name}
          </Link>
        ))}
      </Menu>
    </>
  );
}

MobileMenu.propTypes = {
  categories: T.arrayOf(T.shape({})).isRequired,
};

function GridButtonView({ categories, mobile }) {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmall && mobile) {
    return <MobileMenu categories={categories} />;
  }

  return (
    <Grid
      as="ul"
      container
      spacing={2}
      sx={{ listStyle: "none", marginBlockStart: 0, paddingInlineStart: 0 }}
    >
      {categories.map((category) => (
        <Grid as="li" item md={3} xs={6} key={category.slug}>
          <Link
            key={category.slug}
            href={category.slug}
            type="rectangle_button"
          >
            {category.name}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

GridButtonView.propTypes = {
  categories: T.arrayOf(T.shape({})).isRequired,
  mobile: T.bool,
};

GridButtonView.defaultProps = {
  mobile: false,
};

export default GridButtonView;
