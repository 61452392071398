const COLUMNS = ["column_1", "column_2"];

const ROWS = [
  ["row_1", false, true],
  ["row_2", true, false],
  ["row_3", false, true],
  ["row_4", false, true],
  ["row_5", false, true],
  ["row_6", false, true],
];

export { COLUMNS, ROWS };
