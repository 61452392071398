import { Box, styled } from "components/MUI";

const Wrapper = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  display: "flex",
  flexDirection: "row",
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
  padding: `${theme.spacing(3)} 0`,
  "::-webkit-scrollbar": {
    width: "2px",
    height: "8px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.custom.lightGrey,
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.custom.black,
  },
}));

const StepItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  backgroundColor: "#fff",
  display: "flex",
  flexFlow: "column nowrap",
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  border: `1px solid ${theme.palette.custom.darkGrey}`,
  minWidth: width,
  maxWidth: width,
}));

const StepItemImageWrapper = styled(Box)(() => ({
  marginTop: "auto",
  height: 200,
  position: "relative",
}));

export { Wrapper, StepItem, StepItemImageWrapper };
