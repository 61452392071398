import { useTranslations } from "next-intl";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "components/MUI";

import theme from "config/theme";
import Section from "components/Layout/Section";
import { H2 } from "components/atoms/Typography";
import CustomIcon from "components/atoms/CustomIcon";
import { COLUMNS, ROWS } from "./Comparison.data";

function Comparison() {
  const t = useTranslations();

  return (
    <Section
      sectionStyleProps={{
        id: t("comparison.h2_anchor"),
        maxWidth: false,
        sx: {
          py: theme.spacing(10),
          background: theme.palette.custom.gradient,
        },
      }}
    >
      <Container maxWidth="xl" disableGutters>
        <H2
          align="center"
          sx={{ mb: { xs: theme.spacing(7), md: theme.spacing(5) } }}
        >
          {t("comparison.h2")}
        </H2>
        <TableContainer>
          <Table aria-label="caption table">
            <caption>{t("comparison.table.caption")}</caption>
            <TableHead>
              <TableRow>
                <TableCell />
                {COLUMNS.map((column, key) => (
                  <TableCell
                    key={column}
                    align="center"
                    {...(key === 1 && { sx: { backgroundColor: "#fff" } })}
                  >
                    {t(`comparison.table.${column}`)}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {ROWS.map((row) => (
                <TableRow key={row[0]}>
                  <TableCell component="th" scope="row">
                    {t(`comparison.table.${row[0]}`)}
                  </TableCell>
                  {[...Array(row.length - 1).keys()].map((i) => (
                    <TableCell
                      key={`${row[0]}-${COLUMNS[i]}`}
                      {...(i === 1 && { sx: { backgroundColor: "#fff" } })}
                      align="center"
                    >
                      {row[i + 1] ? (
                        <CustomIcon
                          name="checkmark"
                          title={t("comparison.table.checkmark_icon_title")}
                        />
                      ) : (
                        <CustomIcon
                          name="cross"
                          fontSize="small"
                          fill={theme.palette.custom.darkGrey}
                          title={t("comparison.table.cross_icon_title")}
                        />
                      )}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Section>
  );
}

export default Comparison;
