import { getImageData, getStrapiAttributes } from "./utils";

const getEnrichedCategories = (categories, t) => [
  ...categories.map((item) => ({
    ...item,
    slug: `/${item.slug}`,
  })),
  {
    slug: t("links.all_products.link"),
    name: t("links.all_products.title"),
  },
];

const getConvertedManufacturers = (manufacturers) =>
  manufacturers.map((manufacturer) => ({
    ...manufacturer,
    logo: getImageData(
      manufacturer?.logo?.data
        ? getStrapiAttributes(manufacturer?.logo?.data)
        : null
    ),
  }));

// eslint-disable-next-line import/prefer-default-export
export { getEnrichedCategories, getConvertedManufacturers };
