/* eslint-disable import/prefer-default-export */
import { Button, styled } from "components/MUI";

const StyledMenuButton = styled(Button)(() => ({
  fontWeight: 600,
  width: "max-content",
  ":hover,:focus,:active": {
    backgroundColor: "inherit",
    textDecoration: "underline",
  },
}));

export { StyledMenuButton };
